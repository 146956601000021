import { defineStore } from "pinia";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";

export const useSettings = defineStore("settings", {
  state: () => ({
    _settings: [
      {
        name: "hours",
        value: null,
      },
      {
        name: "locations",
        value: null,
      },
      {
        name: "delivery_zones",
        value: null,
      },
      {
        name: "payments",
        value: null,
      },
      {
        name: "contacts",
        value: null,
      },
      {
        name: "hero_slides",
        value: null,
      },
    ],
    _count: 0,
    _loading: false,
  }),
  getters: {
    settings: (state) => {
      return state._settings;
    },
    hours: (state) => {
      return JSON.parse(
        /* eslint-disable-next-line */
        // @ts-ignore
        state._settings.find((setting) => setting.name === "hours")?.value
      );
    },
    locations: (state) => {
      return JSON.parse(
        /* eslint-disable-next-line */
        // @ts-ignore
        state._settings.find((setting) => setting.name === "locations")
          ?.value || "[]"
      );
    },
    delivery_zones: (state) => {
      return JSON.parse(
        /* eslint-disable-next-line */
        // @ts-ignore
        state._settings.find((setting) => setting.name === "delivery_zones")
          ?.value || "[]"
      );
    },
    payments: (state) => {
      return JSON.parse(
        /* eslint-disable-next-line */
        // @ts-ignore
        state._settings.find((setting) => setting.name === "payments")?.value
      );
    },
    contacts: (state) => {
      return JSON.parse(
        /* eslint-disable-next-line */
        // @ts-ignore
        state._settings.find((setting) => setting.name === "contacts")?.value
      );
    },
    hero_slides: (state) => {
      return JSON.parse(
        /* eslint-disable-next-line */
        // @ts-ignore
        state._settings.find((setting) => setting.name === "hero_slides")
          ?.value || "[]"
      );
    },
    count(state) {
      return state._count;
    },
  },
  actions: {
    async fetchSettings() {
      this._loading = true;
      await ApiService.get("/v1/settings")
        .then((response) => {
          this._settings = response.data.payload;
          this._count = response.data.payload.length;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this._loading = false;
        });
    },
    async updateSetting(setting) {
      this._loading = true;
      await ApiService.post(`/v1/settings`, setting)
        .then((response) => {
          this._settings.push(response.data.payload);
          this._count = this._settings.length;

          ElNotification({
            title: "Success",
            message: "Setting created successfully!",
            offset: 150,
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          ElNotification({
            title: "Error",
            message: error.response.data.message,
            offset: 150,
            type: "error",
          });
          return false;
        })
        .finally(() => {
          this._loading = false;
        });
    },
    async postSlide(slide) {
      this._loading = true;
      await ApiService.post(`/v1/settings/slider`, slide)
        .then(() => {
          ElNotification({
            title: "Success",
            message: "Slide created successfully!",
            offset: 150,
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          ElNotification({
            title: "Error",
            message: error.response.data.message,
            offset: 150,
            type: "error",
          });
          return false;
        })
        .finally(() => {
          this._loading = false;
        });
    },
    async deleteSlide(slide) {
      this._loading = true;
      await ApiService.put(`/v1/settings/slider`, slide)
        .then(() => {
          ElNotification({
            title: "Success",
            message: "Slide deleted successfully!",
            offset: 150,
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          ElNotification({
            title: "Error",
            message: error.response.data.message,
            offset: 150,
            type: "error",
          });
          return false;
        })
        .finally(() => {
          this._loading = false;
        });
    },
  },
});
